import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PageDish from '../component/PageDish';

export default function Sushi() {
  const {
    strapiSushiType: { header, description },
    allStrapiDish: { nodes: sushi },
    strapiMetaPage: { header: headerMeta, description: descriptionMeta },
  } = useStaticQuery(
    graphql`
      query Sushi {
        strapiMetaPage(page_name: { eq: "sushi" }) {
          header
          description
          page_name
        }
        strapiSushiType(urlname: { eq: "sushi" }) {
          header
          description
          urlname
        }
        allStrapiDish(
          filter: { type: { eq: "sushi" } }
          sort: { fields: priority }
        ) {
          nodes {
            id
            posterId
            header
            animationPhoto {
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 5)
                }
              }
            }
            photo {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            price
            type
            description {
              data {
                description
              }
            }
            options {
              id
              posterId
              price
              food_option
            }
            isPremium
            isNew
            weight
          }
        }
      }
    `,
  );

  return (
    <PageDish
      titleHeader={header}
      description={description}
      dishes={sushi}
      title={headerMeta}
      descriptionMeta={descriptionMeta}
    />
  );
}
